import {AssetsSummary, AssetTotals, FutureValueOfExcessAssetsDTO} from "./models/Assets";
import {CreatePersonalAssetRequest, PersonalAsset, UpdatePersonalAssetRequest} from "./models/PersonalAsset";
import {
    CreateStandaloneAccountRequest,
    StandaloneAccount,
    UpdateStandaloneAccountRequest,
} from "./models/StandaloneAccount";
import {HoldingSummary, HoldingWriteModel} from "./models/Holding";
import {
    CreateGeneralInflowRequest,
    GeneralInflow,
    GeneralInflowPresentValueRequest,
    GeneralInflowPresentValueResponse,
    UpdateGeneralInflowRequest
} from "./models/GeneralInflow";
import {get, post, put, remove} from "src/core/api";
import {
    CreatePersonalLiabilityRequest,
    PersonalLiability,
    UpdatePersonalLiabilityRequest
} from "./models/PersonalLiability";
import {AllOrApprovedProduct, ProductSearchResults} from "./models/Product";
import {
    CreateInvestmentProgramRequest, HeldAwayAccount, HeldAwayAccountHoldingsRequest, HeldAwayAccountRequest,
    InvestmentProgramsResponse,
    LegalAgreement,
    RefreshHoldingResponse,
    UpdateLegalAgreementRequest
} from "./models/InvestmentProgram";
import {
    EvaluateSocialSecurityRequest,
    SocialSecurity,
    SocialSecurityBenefits,
    SocialSecurityRequest
} from "./models/SocialSecurity";
import {
    LegalEntityReadModel,
    MemberOwnership
} from "./models/Ownership";
import {CreateLifeInsuranceRequest, LifeInsurance, UpdateLifeInsuranceRequest} from "./models/LifeInsurance";
import {AssetClassifications} from "./models/AssetClassifications";
import {
    AutoCalculateVestingPeriodRequest,
    AutoCalculateVestingPeriodResponse,
    CalculateStockGrantRequest,
    CalculateStockOptionRequest,
    CalculateVestingInfoRequest,
    CalculateVestingInfoResponse,
    EquityCompensationFormData,
    EquityCompensationStockType,
    StockGrant,
    StockOption,
    StockOptionsAndGrantsRequest,
    StockOptionsAndGrantsResponse,
    VestingPeriodWriteModel,
    VestingScheduleResponse
} from "./models/EquityCompensation";
import {FamilyAssets, FamilyAssetsRequest} from "./models/FamilyAssets";
import {ReorderAssetRequest} from "./AssetSummary/ArrangeAssets/ReorderAssets";
import {DomesticTrustAccountWriteModel} from "./InvestmentProgram/DomesticTrustAccountWriteModel";
import {
    CreatePartiallyOwnedInvestmentAccountRequest,
    PartiallyOwnedLegalAgreement, PartiallyOwnedLegalAgreementReadModel,
    UpdatePartiallyOwnedInvestmentAccountRequest
} from "./models/PartiallyOwnedInvestmentAccount";
import {BankingAccountReadModel, BankingHoldingRequest, BankingAccountWriteModel} from "./models/BankingAccount";
import {BankingAccountRequest, BankingAccountsSummary} from "./models/BankingAccounts";

export const postPersonalAsset = async (personalAsset: CreatePersonalAssetRequest | UpdatePersonalAssetRequest): Promise<Response> => {
    return post(`/asset/profiles/${personalAsset.profileId}/personalAssets`, personalAsset);
}

export const postAccounts = async (standaloneAccount: CreateStandaloneAccountRequest | UpdateStandaloneAccountRequest): Promise<StandaloneAccount> => {
    const response = await post(`/asset/profiles/${standaloneAccount.profileId}/standaloneAccounts`, standaloneAccount);
    return response.json();
}

const getAssetClassifications = async (): Promise<AssetClassifications> => {
    return (await get("/asset/assetClassifications")).json()
};

const getAssetsSummary = async (profileId: string): Promise<AssetsSummary> => {
    const response = await get(`/asset/profiles/${profileId}/assets`);
    return response.json();
}

const getAssetTotals = async (profileId: string): Promise<AssetTotals> => {
    const response = await get(`/asset/external/profiles/${profileId}/assetTotals`);
    return response.json();
}

const getAccount = async (profileId: string, accountId: string): Promise<StandaloneAccount> => {
    const response = await get(`/asset/profiles/${profileId}/standaloneAccounts/${accountId}`);
    return response.json();
}

const getBankingAccount = async (profileId: string, accountId: string): Promise<StandaloneAccount> => {
    const response = await get(`/asset/profiles/${profileId}/bankingAccounts/${accountId}`);
    return response.json();
}

export const  postBankingAccounts = async (profileId: string, accounts: BankingAccountWriteModel[]) => {
    return await post(`/asset/profiles/${profileId}/banking/saveAccounts`, accounts);
}

const getUnrealizedCapitalGainsTax = async (profileId: string, accountId: string, isEntityCapitalGains: boolean): Promise<number | null> => {
    const response = await get(`/asset/profiles/${profileId}/standaloneAccounts/${accountId}/unrealizedCapitalGainsTax`, {queryParams: {isEntityCapitalGains}});
    return response.json();
}

const getInvestablePresentValue = async (profileId: string, accountId: string, taxStatus: string): Promise<number | null> => {
    const response = await get(`/asset/profiles/${profileId}/standaloneAccounts/${accountId}/investablePresentValue`,
        {queryParams: {taxStatus}});
    return response.json();
}

const getDeferredTaxLiability = async (profileId: string, accountId: string): Promise<number | null> => {
    const response = await get(`/asset/profiles/${profileId}/standaloneAccounts/${accountId}/deferredTaxLiability`);
    return response.json();
}

const getUnrealizedCapitalGainsTaxLegalAgreement = async (profileId: string, legalAgreementId: string, isEntityCapitalGains: boolean): Promise<number | null> => {
    const response = await get(`/asset/profiles/${profileId}/legalAgreements/${legalAgreementId}/holdings/unrealizedCapitalGainsTax`, {queryParams: {isEntityCapitalGains}});
    return response.json();
}

const getUnrealizedCapitalGainsTaxPartiallyOwnedLegalAgreement = async (profileId: string, partiallyOwnedLegalAgreementId: string, isEntityCapitalGains: boolean): Promise<number | null> => {
    const response = await get(`/asset/profiles/${profileId}/partiallyLegalAgreementId/${partiallyOwnedLegalAgreementId}/holdings/unrealizedCapitalGainsTax`, {queryParams: {isEntityCapitalGains}});
    return response.json();
}

const getInvestablePresentValueForLegalAgreements = async (profileId: string, legalAgreementId: string): Promise<number | null> => {
    const response = await get(`/asset/profiles/${profileId}/legalAgreements/${legalAgreementId}/investablePresentValue`);
    return response.json();
}


const getUnrealizedCapitalGainsTaxHeldAwayAccount = async (profileId: string, accountId: string, isEntityCapitalGains: boolean): Promise<number | null> => {
    const response = await get(`/asset/profiles/${profileId}/heldAwayAccounts/${accountId}/holdings/unrealizedCapitalGainsTax`, {queryParams: {isEntityCapitalGains}});
    return response.json();
}

const getDeferredTaxLiabilityForHeldAwayAccount = async (profileId: string, accountId: string): Promise<number | null> => {
    const response = await get(`/asset/profiles/${profileId}/heldAwayAccounts/${accountId}/deferredTaxLiability`);
    return response.json();
}

const getInvestablePresentValueForHeldAwayAccount = async (profileId: string, accountId: string, taxStatus: string): Promise<number | null> => {
    const response = await get(`/asset/profiles/${profileId}/heldAwayAccounts/${accountId}/investablePresentValue`, {queryParams: {taxStatus}});
    return response.json();
}



const getUnrealizedCapitalGainsTaxBankingAccount = async (profileId: string, accountId: string, isEntityCapitalGains: boolean): Promise<number | null> => {
    const response = await get(`/asset/profiles/${profileId}/banking/bankingAccounts/${accountId}/holdings/unrealizedCapitalGainsTax`, {queryParams: {isEntityCapitalGains}});
    return response.json();
}

const getDeferredTaxLiabilityForBankingAccount = async (profileId: string, accountId: string): Promise<number | null> => {
    const response = await get(`/asset/profiles/${profileId}/banking/bankingAccounts/${accountId}/deferredTaxLiability`);
    return response.json();
}

const getInvestablePresentValueForBankingAccount = async (profileId: string, accountId: string, taxStatus: string): Promise<number | null> => {
    const response = await get(`/asset/profiles/${profileId}/banking/bankingAccounts/${accountId}/investablePresentValue`, {queryParams: {taxStatus}});
    return response.json();
}




const deleteAccount = async (profileId: string, accountId: string): Promise<Response> => {
    return remove(`/asset/profiles/${profileId}/standaloneAccounts/${accountId}`);
}

const deleteBankingAccount = async (profileId: string, bankingAccountId: string): Promise<Response> => {
    return remove(`/asset/profiles/${profileId}/banking/bankingAccounts/${bankingAccountId}`);
}

export const postHoldings = async (profileId: string, accountId: string, holdings: HoldingWriteModel[]): Promise<void> => {
    await post(`/asset/profiles/${profileId}/standaloneAccounts/${accountId}/holdings`, holdings);
}

const getPersonalAsset = async (profileId: string, assetId: string): Promise<PersonalAsset> => {
    const response = await get(`/asset/profiles/${profileId}/personalAssets/${assetId}`)
    return response.json();
}

const deletePersonalAsset = async (profileId: string, assetId: string): Promise<Response> => {
    return remove(`/asset/profiles/${profileId}/personalAssets/${assetId}`)
}

export const postGeneralInflow = async (generalInflow: CreateGeneralInflowRequest): Promise<Response> => {
    return post(`/asset/profiles/${generalInflow.profileId}/generalInflows`, generalInflow);
}

export const putGeneralInflow = async (generalInflow: UpdateGeneralInflowRequest): Promise<Response> => {
    return put(`/asset/profiles/${generalInflow.profileId}/generalInflows`, generalInflow);
}

export const getGeneralInflow = async (profileId: string, assetId: string): Promise<GeneralInflow> => {
    const response = await get(`/asset/profiles/${profileId}/generalInflows/${assetId}`);
    return response.json();
}

export const deleteGeneralInflow = async (profileId: string, assetId: string): Promise<Response> => {
    return remove(`/asset/profiles/${profileId}/generalInflows/${assetId}`)
}

export const postSocialSecurity = async (socialSecurity: SocialSecurityRequest): Promise<Response> => {
    return post(`/asset/profiles/${socialSecurity.profileId}/socialSecurity`, socialSecurity);
}

export const putSocialSecurity = async (socialSecurity: SocialSecurityRequest, assetId: string): Promise<Response> => {
    return put(`/asset/profiles/${socialSecurity.profileId}/socialSecurity/${assetId}`, socialSecurity);
}

export const getSocialSecurity = async (profileId: string, assetId: string): Promise<SocialSecurity> => {
    const response = await get(`/asset/profiles/${profileId}/socialSecurity/${assetId}`);
    return response.json();
}

export const getSocialSecuritiesByProfileId = async (profileId: string): Promise<SocialSecurity[]> => {
    const response = await get(`/asset/profiles/${profileId}/socialSecurities`);
    return response.json();
}

export const deleteSocialSecurity = async (profileId: string, socialSecurityId: string): Promise<Response> => {
    return remove(`/asset/profiles/${profileId}/socialSecurity/${socialSecurityId}`);
}

async function evaluateSocialSecurity(profileId: string, memberId: string, socialSecurityValues: EvaluateSocialSecurityRequest): Promise<SocialSecurityBenefits> {
    const response = await post(`/asset/profiles/${profileId}/socialSecurity/evaluate`, {
        memberId,
        ...socialSecurityValues
    });
    return response.json();
}

export const postLifeInsurance = async (profileId: string, request: CreateLifeInsuranceRequest): Promise<Response> => {
    return post(`/asset/profiles/${profileId}/lifeInsurance`, request);
}

async function getLifeInsurance(profileId: string, assetId: string): Promise<LifeInsurance> {
    const response = await get(`/asset/profiles/${profileId}/lifeInsurance/${assetId}`)
    return response.json();
}

async function putLifeInsurance(lifeInsurance: UpdateLifeInsuranceRequest): Promise<void> {
    await put(`/asset/profiles/${lifeInsurance.profileId}/lifeInsurance/${lifeInsurance.id}`, lifeInsurance);
}

const deleteLifeInsurance = async (profileId: string, assetId: string): Promise<Response> => {
    return remove(`/asset/profiles/${profileId}/lifeInsurance/${assetId}`);
}

async function getStockOptionsAndGrants(profileId: string, assetId: string): Promise<StockOptionsAndGrantsResponse> {
    const response = await get(`/asset/profiles/${profileId}/equityCompensation/${assetId}/stockOptionsAndGrants`)
    return response.json();
}

async function postStockOptionsAndGrants(profileId: string, assetId: string, request: StockOptionsAndGrantsRequest): Promise<StockOptionsAndGrantsResponse> {
    const response = await post(`/asset/profiles/${profileId}/equityCompensation/${assetId}/stockOptionsAndGrants`, request);
    return response.json();
}

async function getVestingSchedule(profileId: string, assetId: string, stockType: EquityCompensationStockType, stockId: string): Promise<VestingScheduleResponse> {
    const response = await get(`/asset/profiles/${profileId}/equityCompensation/${assetId}/${stockType}/vestingSchedule/${stockId}`)
    return response.json();
}

async function postVestingPeriods(profileId: string, assetId: string, stockType: EquityCompensationStockType, stockId: string, request: VestingPeriodWriteModel[]): Promise<VestingScheduleResponse> {
    const response = await post(`/asset/profiles/${profileId}/equityCompensation/${assetId}/${stockType}/vestingSchedule/${stockId}`, request);
    return response.json();
}

async function autoCalculateVestingPeriods(profileId: string, request: AutoCalculateVestingPeriodRequest): Promise<AutoCalculateVestingPeriodResponse> {
    const response = await post(`/asset/profiles/${profileId}/equityCompensation/autoCalculateVestingSchedule`, request);
    return response.json();
}

async function calculateStockOption(profileId: string, request: CalculateStockOptionRequest): Promise<StockOption> {
    const response = await post(`/asset/profiles/${profileId}/equityCompensation/calculateStockOption`, request);
    return response.json();
}

async function calculateStockGrant(profileId: string, request: CalculateStockGrantRequest): Promise<StockGrant> {
    const response = await post(`/asset/profiles/${profileId}/equityCompensation/calculateStockGrant`, request);
    return response.json();
}

async function calculateVestingInfoForStockGrantOrOption(profileId: string, request: CalculateVestingInfoRequest): Promise<CalculateVestingInfoResponse> {
    const response = await post(`/asset/profiles/${profileId}/equityCompensation/calculateVestingInfoForStockGrantOrOption`, request);
    return response.json();
}

async function getEquityCompensation(profileId: string, assetId: string): Promise<EquityCompensationFormData> {
    const response = await get(`/asset/profiles/${profileId}/equityCompensation/${assetId}`)
    return response.json();
}

const postEquityCompensation = async (profileId: string, request: EquityCompensationFormData): Promise<EquityCompensationFormData> => {
    const response = await post(`/asset/profiles/${profileId}/equityCompensation`, request);
    return response.json();
}

const calculateEquityCompensationSummary = async (profileId: string, request: EquityCompensationFormData): Promise<EquityCompensationFormData> => {
    const response = await post(`/asset/profiles/${profileId}/equityCompensation/calculateSummary`, request);
    return response.json();
}

const putEquityCompensation = async (equityCompensationRequest: EquityCompensationFormData): Promise<EquityCompensationFormData> => {
    const response = await put(`/asset/profiles/${equityCompensationRequest.profileId}/equityCompensation`, equityCompensationRequest);
    return response.json();
}

const deleteEquityCompensation = async (profileId: string, assetId: string): Promise<Response> => {
    return remove(`/asset/profiles/${profileId}/equityCompensation/${assetId}`);
}

export const postPersonalLiability = async (personalLiability: CreatePersonalLiabilityRequest): Promise<Response> => {
    return post(`/asset/profiles/${personalLiability.profileId}/personalLiabilities`, personalLiability);
}

export const putPersonalLiability = async (personalLiability: UpdatePersonalLiabilityRequest): Promise<void> => {
    await put(`/asset/profiles/${personalLiability.profileId}/personalLiabilities`, personalLiability);
}

export const getPersonalLiability = async (profileId: string, personalLiabilityId: string): Promise<PersonalLiability> => {
    const response = await get(`/asset/profiles/${profileId}/personalLiabilities/${personalLiabilityId}`);
    return response.json();
}

export const deletePersonalLiability = async (profileId: string, personalLiabilityId: string): Promise<Response> => {
    return remove(`/asset/profiles/${profileId}/personalLiabilities/${personalLiabilityId}`);
}

export const calculateGeneralInflowPresentValue = async (profileId: string, request: GeneralInflowPresentValueRequest): Promise<GeneralInflowPresentValueResponse> => {
    const response = await post(`/asset/profiles/${profileId}/generalInflowPresentValue`, request);
    return response.json();
}

async function searchProducts(profileId: string, searchTerm: string, page?: number, productSearchType?: AllOrApprovedProduct): Promise<ProductSearchResults> {
    const response = await get(`/asset/profiles/${profileId}/products`, {
        queryParams: {
            search: searchTerm,
            page: page && page > 1 ? page.toString() : "1",
            searchType: productSearchType === "approvedProducts" ? "APPROVED_PRODUCTS" : ""
        }
    })
    return response.json();
}

export type GetInvestmentProgramsParams = {
    team: boolean, page: number, pageSize: number, groupName?: string, portfolioManager?: string
}

async function getInvestmentPrograms(
    profileId: string, params: GetInvestmentProgramsParams): Promise<InvestmentProgramsResponse> {
    const response = await get(`/asset/profiles/${profileId}/investmentPrograms`, {
        queryParams: {
            team: params.team,
            page: params.page.toString(),
            pageSize: params.pageSize.toString(),
            groupName: params.groupName || "",
            portfolioManager: params.portfolioManager || ""
        }
    });
    return response.json();
}

async function getDomesticTrustHoldings(profileId: string, legalAgreementId: string, type: string): Promise<HoldingSummary> {
    const response = await get(`/asset/profiles/${profileId}/legalAgreements/${legalAgreementId}/holdings`, {queryParams: {type}});
    return response.json();
}

export const getDomesticTrustHoldingsForPOIAPreSave = async (financialAccountPersistentIds: string[], profileId: string): Promise<HoldingSummary> => {
    const response = await post(`/asset/profiles/${profileId}/partiallyOwnedInvestments/accountHoldings`, financialAccountPersistentIds);
    return response.json();
}

const deletePartiallyOwnedInvestmentAccount = async (profileId: string, partiallyOwnedInvestmentAccount: string): Promise<Response> => {
    return remove(`/asset/profiles/${profileId}/partiallyOwnedInvestments/${partiallyOwnedInvestmentAccount}`);
}

export const postInvestmentProgram = async (investmentProgram: CreateInvestmentProgramRequest): Promise<Response> => {
    return post(`/asset/profiles/${investmentProgram.profileId}/investmentPrograms`, investmentProgram);
}

export const refreshHoldings = async (profileId: string): Promise<RefreshHoldingResponse> => {
    const response = await post(`/asset/profiles/${profileId}/refreshHoldings`, "");
    if (response.status === 200 && response.statusText != null) {
        return response.json();
    }
    return null as unknown as RefreshHoldingResponse;
}

export const deleteInvestmentProgram = async (profileId: string): Promise<Response> => {
    return remove(`/asset/profiles/${profileId}/investmentPrograms`)
}

export const deleteHolding = async (profileId: string, holdingId: string): Promise<Response> => {
    return remove(`/asset/profiles/${profileId}/holdings/${holdingId}`);
}

export const getLegalAgreement = async (profileId: string, legalAgreementId: string): Promise<LegalAgreement> => {
    const response = await get(`/asset/profiles/${profileId}/legalAgreements/${legalAgreementId}`);
    return response.json();
}

export const putLegalAgreement = async (profileId: string, legalAgreementId: string, legalAgreement: UpdateLegalAgreementRequest): Promise<Response> => {
    return put(`/asset/profiles/${profileId}/legalAgreements/${legalAgreementId}`, legalAgreement);
}

export const putLegalAgreementHoldings = async (profileId: string,
                                                domesticTrustAccountWriteModel: DomesticTrustAccountWriteModel): Promise<Response> => {
    return put(`/asset/profiles/${profileId}/legalAgreementHoldings`, domesticTrustAccountWriteModel);
}

async function getLegalEntities(profileId: string): Promise<LegalEntityReadModel[]> {
    const response = await get(`/asset/profiles/${profileId}/legalEntities`)
    return response.json();
}

export const getHeldAwayAccount = async (profileId: string, accountId: string): Promise<HeldAwayAccount> => {
    const response = await get(`/asset/profiles/${profileId}/heldAwayAccounts/${accountId}`);
    return response.json();
}

async function getHoldingsForHeldAwayAccounts(profileId: string, accountId: string): Promise<HoldingSummary> {
    const response = await get(`/asset/profiles/${profileId}/heldAwayAccounts/${accountId}/holdings`);
    return response.json();
}

export const putHeldAwayAccount = async (profileId: string,
                                         accountId: string,
                                         heldAwayAccount: HeldAwayAccountRequest): Promise<Response> => {
    return put(`/asset/profiles/${profileId}/heldAwayAccounts/${accountId}`, heldAwayAccount);
}

export const updateBankAccount = async (profileId: string,
                                         bankingAccountId: string,
                                         bankingAccount: BankingAccountRequest): Promise<Response> => {
    return put(`/asset/profiles/${profileId}/banking/updateAccounts/${bankingAccountId}`, bankingAccount);
}

export const putHeldAwayAccountHoldings = async (profileId: string, accountId: string,
                                                 heldAwayAccountHoldingsRequest: HeldAwayAccountHoldingsRequest): Promise<Response> => {
    return put(`/asset/profiles/${profileId}/heldAwayAccounts/${accountId}/holdings`, heldAwayAccountHoldingsRequest);
}

async function getTaxRate(profileId: string): Promise<number> {
    const response = await get(`/asset/profiles/${profileId}/taxRate`);
    return response.json();
}

async function getMemberOwnerships(profileId: string, memberId: string): Promise<MemberOwnership[]> {
    const response = await get(`/asset/external/profiles/${profileId}/assets/memberOwnerships`, {queryParams: {memberId: memberId}});
    return response.json();
}

async function getFamilyAssets(profileId: string, memberId: string): Promise<FamilyAssets> {
    const response = await get(`/asset/profiles/${profileId}/familyAssets/${memberId}`);
    return response.json();
}

export const postFamilyAssets = async (profileId: string, request: FamilyAssetsRequest): Promise<Response> => {
    return post(`/asset/profiles/${profileId}/familyAssets`, request);
}

export const getFutureValueOfExcessAssets = async (profileId: string): Promise<FutureValueOfExcessAssetsDTO> => {
    const response = await get(`/asset/external/profiles/${profileId}/futureValueOfExcessAssets`);
    return response.json();
}

export const deleteLegalEntity = async (profileId: string, legalEntityId: string): Promise<Response> => {
    return remove(`/asset/profiles/${profileId}/legalEntities/${legalEntityId}`);
}

export const putAssetsOrder = async (profileId: string, request: ReorderAssetRequest): Promise<Response> => {
    return put(`/asset/external/profiles/${profileId}/assets`, request);
}

export const searchPartiallyOwnedInvestments = async (profileId: string, accountNumber: string): Promise<PartiallyOwnedLegalAgreement> => {
    const response = await get(
        `/asset/profiles/${profileId}/partiallyOwnedInvestments`,
        {
            queryParams: {
                accountNumber
            }
        }
    );

    return response.json();
}

export const getBankingAccounts = async (profileId: string, ulek: string, isProfile: boolean): Promise<BankingAccountReadModel[]> => {
    const response = await get(
        `/asset/profiles/${profileId}/banking/accounts`,
        {
            queryParams: {
                ulek,
                isProfile
            }
        }
    );
    return response.json();
}

export const getBankingAccountById = async (profileId: string, accountId: string): Promise<BankingAccountsSummary> => {
    const response = await get(
        `/asset/profiles/${profileId}/banking/accounts/${accountId}`
    );
    return response.json();
}

export const getBankingAccountByAccNumber = async (profileId: string, accountNumber: string): Promise<BankingAccountReadModel[]> => {
    const response = await get(
        `/asset/profiles/${profileId}/banking/searchAccount/${accountNumber}`
    );
    return response.json();
}

export const getBankingAccountHoldings = async (profileId: string, accountId: string): Promise<HoldingSummary> => {
    const response = await get(`/asset/profiles/${profileId}/banking/${accountId}/holdings`);
    return response.json();
}

export const putBankingAccountHoldings = async(profileId: string, accountId: string, holdingsRequest: BankingHoldingRequest): Promise<Response> => {
    return put(`/asset/profiles/${profileId}/banking/${accountId}/holdings`, holdingsRequest);
}

export const validatePartiallyOwnedInvestment = async (profileId: string, legalAgreementNumber: string): Promise<Response> => {
    return await get(`/asset/profiles/${profileId}/partiallyOwnedInvestments/${legalAgreementNumber}/validation`);
}

export const getPartiallyOwnedInvestment = async (profileId: string, partiallyOwnedLegalAgreementId: string): Promise<PartiallyOwnedLegalAgreementReadModel> => {
    const response = await get(`/asset/profiles/${profileId}/partiallyOwnedInvestments/${partiallyOwnedLegalAgreementId}`);
    return response.json();
}

export const postPartiallyOwnedInvestment = async (
    profileId: string,
    request: CreatePartiallyOwnedInvestmentAccountRequest | UpdatePartiallyOwnedInvestmentAccountRequest): Promise<PartiallyOwnedLegalAgreement> => {
    const response = await post(`/asset/profiles/${profileId}/partiallyOwnedInvestments`, request);
    return response.json();
}



export const assetsApiClient = {
    getAssetClassifications,
    getAssetsSummary,
    getAssetTotals,
    getAccount,
    getUnrealizedCapitalGainsTax,
    getDeferredTaxLiability,
    getDeferredTaxLiabilityForHeldAwayAccount,
    getDeferredTaxLiabilityForBankingAccount,
    postAccounts,
    deleteAccount,
    deleteBankingAccount,
    getPersonalAsset,
    postPersonalAsset,
    deletePersonalAsset,
    postHoldings,
    putLegalAgreementHoldings,
    postGeneralInflow,
    putGeneralInflow,
    getGeneralInflow,
    deleteGeneralInflow,
    postSocialSecurity,
    getSocialSecurity,
    getSocialSecuritiesByProfileId,
    putSocialSecurity,
    deleteSocialSecurity,
    evaluateSocialSecurity,
    postLifeInsurance,
    getLifeInsurance,
    putLifeInsurance,
    deleteLifeInsurance,
    postEquityComp: postEquityCompensation,
    postPersonalLiability,
    putPersonalLiability,
    getPersonalLiability,
    deletePersonalLiability,
    calculateGeneralInflowPresentValue,
    searchProducts,
    deleteHolding,
    getInvestmentPrograms,
    getDomesticTrustHoldings,
    postInvestmentProgram,
    refreshHoldings,
    deleteInvestmentProgram,
    getLegalAgreement,
    getUnrealizedCapitalGainsTaxLegalAgreement,
    getUnrealizedCapitalGainsTaxHeldAwayAccount,
    getUnrealizedCapitalGainsTaxBankingAccount,
    putLegalAgreement,
    getHeldAwayAccount,
    getHoldingsForHeldAwayAccounts,
    putHeldAwayAccount,
    putHeldAwayAccountHoldings,
    getLegalEntities,
    getTaxRate,
    getMemberOwnerships,
    getEquityCompensation,
    postEquityCompensation,
    putEquityCompensation,
    deleteEquityCompensation,
    getStockOptionsAndGrants,
    getFamilyAssets,
    postFamilyAssets,
    postStockOptionsAndGrants,
    getFutureValueOfExcessAssets,
    getVestingSchedule,
    postVestingPeriods,
    calculateStockOption,
    calculateStockGrant,
    calculateEquityCompensationSummary,
    autoCalculateVestingPeriods,
    calculateVestingInfoForStockGrantOrOption,
    deleteLegalEntity,
    getInvestablePresentValue,
    getInvestablePresentValueForLegalAgreements,
    getInvestablePresentValueForHeldAwayAccount,
    getInvestablePresentValueForBankingAccount,
    searchPartiallyOwnedInvestments,
    validatePartiallyOwnedInvestment,
    postPartiallyOwnedInvestment,
    deletePartiallyOwnedInvestmentAccount,
    getPartiallyOwnedInvestment,
    getUnrealizedCapitalGainsTaxPartiallyOwnedLegalAgreement,
    getDomesticTrustHoldingsForPOIAPreSave,
    getBankingAccounts,
    getBankingAccountById,
    updateBankAccount,
    postBankingAccounts,
    getBankingAccountHoldings,
    putBankingAccountHoldings,
    getBankingAccountByAccNumber
};
