import React, {useEffect, useState} from "react";
import {formatCurrency} from "../../../utils/format";
import {Button} from "../../../components";
import {BankingAccountReadModel, BankingAccountWriteModel} from "../../models/BankingAccount";
import {useAppSelector} from "../../../store/hooks";
import {selectProfile} from "../../../ClientManagement/ClientProfile/activeProfileSlice";
import AddBankingAccountModal from "./AddBankingAccountModal";

export type CheckedAccounts = {
    [key: string]: boolean
}

export type BankAccountSearchResultsProps = {
    bankingAccounts: BankingAccountReadModel[],
    onSave: (selectedAccounts: BankingAccountWriteModel[]) => void,
    externalAccountNumber: string
};

export function BankAccountSearchResults({
                                             bankingAccounts,
                                             onSave,
                                             externalAccountNumber
                                         }: BankAccountSearchResultsProps) {

    const noValue = <span className="no-value">&ndash;&ndash;</span>;

    const [isButtonEnabled, setIsButtonEnabled] = useState<boolean>(false);
    const [isHeaderChecked, setIsHeaderChecked] = useState<boolean>(false);
    const [checkedItems, setCheckedItems] = useState<CheckedAccounts>({});
    const profile = useAppSelector(selectProfile);
    const [accountsToSave, setAccountsToSave] = useState<BankingAccountWriteModel[]>([]);
    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

    useEffect(() => {
        if (externalAccountNumber.length > 0) {
            setCheckedItems(prev => ({...prev, [externalAccountNumber]: true}));
            setIsButtonEnabled(true);
        }
    }, [externalAccountNumber]);

    const handleHeaderChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const checked = e.target.checked;
        const newCheckedItems = bankingAccounts.reduce((acc, account) => {
            acc[account.accountNumber] = checked;
            return acc;
        }, {} as CheckedAccounts);
        setCheckedItems(newCheckedItems);
        setIsHeaderChecked(checked);
        setIsButtonEnabled(checked);
    }

    const handleItemChange = (accountNumber: string) => {
        setCheckedItems(prev => {
            const newCheckedItems = {...prev, [accountNumber]: !prev[accountNumber]};
            const isAnyItemChecked = Object.values(newCheckedItems).some(value => value);
            const allItemsChecked = bankingAccounts.every(account => newCheckedItems[account.accountNumber]);
            setIsButtonEnabled(isAnyItemChecked);
            setIsHeaderChecked(allItemsChecked);
            return newCheckedItems;
        });
    };

    const handleAddAccount = () => {
        const selected = bankingAccounts.filter(
            account => checkedItems[account.accountNumber] && !account.isSelected);
        const confirmedAccounts: BankingAccountWriteModel[] = selected.map(account => ({
            ...account,
            profileId: profile.id,
            ownershipCategory: "Sole",
            memberOwnerships: [],
            legalEntityOwnerships: [],
            isSelected: true
        }));
        setAccountsToSave(confirmedAccounts);
        setIsModalOpen(true);
    };

    const handleConfirm = () => {
        onSave(accountsToSave);
        setIsModalOpen(false);
    }

    const sortedBankingAccounts = [...bankingAccounts].sort((a, b) => {
        if (a.isSelected && !b.isSelected) return 1;
        if (!a.isSelected && b.isSelected) return -1;
        return 0;
    });

    const isAllSelected = sortedBankingAccounts.every(account => account.isSelected);

    return (
        <div>
            <div aria-label="Search Results" className="banking-account-table">
                <div className="banking-account-table-header banking-account-grid">
                    <span className="marginleft-md">
                        <input className={`display-inherit`}
                               aria-label={`header-select`}
                               type="checkbox"
                               checked={isHeaderChecked || isAllSelected}
                               onChange={handleHeaderChange}
                               disabled={isAllSelected}
                        />
                    </span>
                    <span>Name</span>
                    <span>Account Number</span>
                    <span>Entity Type</span>
                    <span className="textalign-right">As of Date</span>
                    <span className="textalign-right">Present Value</span>
                </div>
                {
                    bankingAccounts.map((bankingAccount) => {
                        const isChecked = checkedItems[bankingAccount.accountNumber] || false;
                        return <div role="row" key={bankingAccount.accountPersistentId}>
                            <div
                                aria-label={`Account ${bankingAccount.accountNumber}`}
                                className="banking-account-table-row banking-account-grid"
                            >
                                <span className="marginleft-md">
                                    <input className={`display-inherit`}
                                           aria-label={`item-select`}
                                           type="checkbox"
                                           checked={bankingAccount.isSelected ? true : isChecked}
                                           onChange={() => handleItemChange(bankingAccount.accountNumber)}
                                           disabled={!!bankingAccount.isSelected}
                                    />
                                </span>
                                <span>{bankingAccount.accountName}</span>
                                <span>{bankingAccount.accountNumber}</span>
                                <span>{bankingAccount.entityType}</span>
                                <span className="textalign-right">{bankingAccount.valueAsOfDate}</span>
                                <span className="textalign-right">
                                    {bankingAccount.eodNtBalance !== null ? `${formatCurrency(bankingAccount.eodNtBalance)}` : noValue}
                                </span>
                            </div>
                        </div>
                    })
                }
                <div
                    aria-label={`Add Account to Profile`}
                    className="banking-account-table-row add-account-row">
                    <Button
                        className="search-button marginleft-sm"
                        icon="left"
                        iconName="add"
                        kind="primary"
                        onClick={handleAddAccount}
                        size="medium"
                        type="button"
                        disabled={!isButtonEnabled}
                    >
                        Add Account(s) to Profile
                    </Button>
                </div>
                {isModalOpen &&
                    <AddBankingAccountModal
                        bankingAccounts={accountsToSave}
                        onConfirm={handleConfirm}
                        onCancel={() => setIsModalOpen(false)}
                    />
                }
            </div>
        </div>
    )
}