import {Button, Search} from "../../../components";
import React, {KeyboardEvent, useState} from "react";

export type BankingAccountSearchProps = {
    onSearch: (search: string) => void,
    onSearchCleared: () => void
};

export function BankingAccountSearch({
                                         onSearch,
                                         onSearchCleared
                                     }: BankingAccountSearchProps) {
    const [searchAccountNumber, setSearchAccountNumber] = useState('');

    const handleAccountSearch = () => {
        let paddedAccountNumber = searchAccountNumber;
        if (searchAccountNumber.trim().length > 0) {
            if (searchAccountNumber.length < 11) {
                paddedAccountNumber = searchAccountNumber.padStart(11, '0');
            }
            onSearch(paddedAccountNumber);
        }
    };

    const handleSearchCleared = () => {
        setSearchAccountNumber("");
        onSearchCleared();
    };

    return (
        <>
            <Search
                id="search-input"
                name="search_banking_account"
                size="medium"
                onClearListener={handleSearchCleared}
                placeholder="Search by full account number"
                handleChangeVal={(e: React.ChangeEvent<HTMLInputElement>) => {
                    setSearchAccountNumber(e.target.value);
                }}
                onKeyDownListener={(e: KeyboardEvent) => {
                    if (e.key === 'Enter') {
                        e.preventDefault();
                        handleAccountSearch();
                    }
                }}
                value={searchAccountNumber}
            />
            <Button
                className="search-button"
                icon="none"
                kind="primary"
                onClick={handleAccountSearch}
                size="medium"
                type="button"
            >
                Search
            </Button>
        </>
    );
}